import { OrderItem } from "~/types/orders";
import { InventoryProduct } from "~/types/inventory";
import { Product, ProductProperty } from "~/types/products";

export const useGetProductSubtitle = (
  product: Product | InventoryProduct | OrderItem,
  separator?: string
) => {
  const variants = (product?.properties as ProductProperty[])
    ?.filter((i) => !i.symbol_html?.length)
    ?.map((prop) => prop.title)
    ?.join(separator || ", ");

  if (useConfig().isProductSecondaryName && product?.ext_title && variants) {
    return `${product?.ext_title}${separator || ","} ${variants}`;
  } else if (product?.ext_title && useConfig().isProductSecondaryName) {
    return product?.ext_title;
  } else if (variants) {
    return variants;
  }
};
